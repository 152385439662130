@import 'src/styles/vars';

.titleContainer {
  display: flex;
  flex-direction: column;
}

.titleBlock {
  display: flex;
  align-items: center;
}

.title {
  font-size: 14px;
  font-weight: 600;
  color: $color-black-900;
}

.rating {
  color: $color-gray-300;
  font-size: 14px;
}

.starIcon {
  flex-shrink: 0;
  margin-left: 2px;
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ratingBlock {
  display: flex;
}

.status {
  width: fit-content;
  padding: 0 12px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  cursor: pointer;

  &.gray {
    background-color: $color-gray-100;
    color: $color-black-400;
  }

  &.green {
    background: $color-green--light;
    color: $color-green--dark;
  }

  &.yellow {
    color: $color-yellow--dark;
    background: $color-yellow--light;
  }

  &.red {
    background: $color-red--light;
    color: $color-red;
  }
}

.carwashClass {
  border: 1px solid red;
  margin-top: 1px;
  position: relative;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: $color-blue;
  cursor: pointer;

  & svg {
    position: absolute;
    top: 2.5px;
    margin-left: 2px;
  }
}

.schedule {
  position: relative;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: $color-gray-bluish;
}

.tagsContainer {
  margin: 8px 0 12px -24px;
  width: calc(100% + 48px);
  padding: 0 24px;
  display: flex;
  gap: 8px;
  height: 32px;

  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}