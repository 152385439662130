.container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.slider {
  width: 80%;
}

.photo {
  width: 100%;
  min-height: 150px;
  object-fit: cover;
}

.closeIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 11;
  color: white;
}

.emptyBlock {
  color: white;
}

.dots {
  bottom: -45px;
}