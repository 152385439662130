.root {
  margin-bottom: 56px;
}

.title {
  font-size: 24px;
  font-weight: 600;
}

.rating {
  margin-top: 16px;
}

.comment {
  margin-top: 16px;
}