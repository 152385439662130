@import 'styles/vars';
@import 'styles/mixins';

.wrapper {
  scroll-snap-align: start;
  min-width: calc(76px * 1.6);
  padding-left: 16px;
  box-sizing: content-box;

  &:first-child {
    padding-left: 24px;
  }

  &.extended {
    padding-left: 24px;
    width: calc(100% - 24px);
    flex: 1 0 auto;
  }
}

.product {
  width: 100%;
  position: relative;
  overflow: hidden;
  word-wrap: break-word;
  min-height: 76px;
  padding: 5px 7px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $color-gray-100;
  border: $color-gray-150 1px solid;

  @include transition([min-height background], 0.3s, ease-out);

  &.current {
    background: $color-gray-150;
  }

  &.active {
    color: $color-blue;
    background: $color-blue--light;

    .description {
      color: $color-blue;
    }

    .productFooter {
      font-size: 12px;
      font-weight: normal;
    }
  }

  &.extended {
    flex-direction: row;
    min-height: 206px;
    justify-content: space-between;
    padding: 8px;

    .productFooter {
      font-weight: 700;
      font-size: 16px;
      color: white;
      padding: 0 7px;
      background: $color-blue;
      border-radius: 10px;
      height: 24px;
      line-height: 24px;
    }

    .title {
      font-size: 16px;
    }

    .description {
      font-size: 12px;
      margin-top: 10px;
    }
  }
}

.productFooter {
  font-weight: 700;
  font-size: 16px;
  color: $color-blue;
  text-align: right;
}

.title {
  font-size: 12px;
  font-weight: 600;
}

.description {
  font-size: 10px;
  margin-top: 1px;
  color: $color-gray-400;
  word-wrap: break-word;
}
