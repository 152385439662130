@import 'src/styles/vars';
@import 'src/styles/mixins';

.carwashInfo {
  margin-top: 8px;
  padding: 0 $screen-indent;
}

.subscriptionsTitle {
  font-weight: 600;
  color: $color-gray-400;
  padding-left: 26px;
  font-size: 14px;
}

.subscriptionPeriod,
.activeSubscriptionPeriod {
  font-size: 12px;
}

.content {
  padding-bottom: $screen-indent;
}

.activeSubscription {
  width: calc(100% - 52px);
  box-sizing: border-box !important;
  border-radius: 10px;
  margin: 8px auto 16px;
  padding: 5px 8px;
  cursor: pointer;
  background: $color-blue;
  color: white;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
  min-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include touch-transition(box-shadow);

  &:hover {
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.1);
  }
}

.activeSubscriptionArrow {
  width: 12px !important;
  height: 10px !important;
}

.groupWrapper:nth-child(1) {
  margin-top: -14px;
}

.groupName {
  color: $color-gray-400;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 8px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;

  &.bottom {
    justify-content: flex-end;
    height: 18px;
  }
}

.activeSubscriptionTitle {
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;

  @include ellipsis;
}

.description {
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
  color: $color-gray-400;
  flex-shrink: 0;
}

.period {
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
  color: $color-blue;
  flex-shrink: 0;
}

.chooseCarwashSign {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70%;
  color: $color-main-black;
  font-weight: 500;
}
