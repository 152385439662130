@import 'styles/vars';
@import 'styles/mixins';

.container {
  display: flex;
  flex-direction: column;
}

.content {
  z-index: 15;
  background: $color-white;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow-x: auto;
}

.tariffBlock {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.tariffTitle {
  font-size: 12px;
  color: $color-gray-400;
}
.descriptionTitle {
  font-size: 14px;
  color: $color-gray-400;
  margin-top: 16px;
  font-weight: bold;
}
