@import 'styles/vars';

.appearance {
    height: 60px;
    width: 60px;
    background: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 4px 4px 20px 0 #00000026;

    &.small {
        height: 40px;
        width: 40px;
    }

    &.disabled {
        background: $color-gray-disabled;
        cursor: auto;
    }
}

.root {
    position: absolute;
    right: $screen-indent;
    bottom: 128px;
}

.mobileRoot {
    position: absolute;
    top: -64px;
    right: $screen-indent;
}