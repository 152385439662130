@import 'styles/vars';

.rangeWrapper {
  position: relative;
}

.range {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  background-color: $color-gray-range;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;

  &:hover, &:active {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 12px;
    height: 12px;
    background: $color-gray-bluish;
    cursor: pointer;
    border-radius: 50%;

    &:hover, &:active  {
      background-color: $color-blue;
    }
  }
}

.active {
  &::-webkit-slider-thumb {
    background: $color-blue;
  }
}

.rangeValue {
  position: absolute;
  left: 50%;
  top: 23px;
  transform: translateX(-50%);
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: $color-gray-bluish;
}