.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  font-weight: 600;
}

.switch {
  flex: 0 0 auto;
}
