@import 'styles/vars';

.content {
  padding-bottom: $screen-indent;
}

.btn {
  background: $color-black-900;
  color: white;
  width: auto;
  z-index: 1;
}

.txBtn {
  background: $color-yellow--dark !important;
  color: $color-black-900 !important;
}

.checkText {
  width: 100%;
  margin-bottom: 9px;
  text-align: center;
  font-size: 12px;
  color: $color-red;
}

.attentionText {
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
}

.confirmText {
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  margin-top: 4px;
}

.imageBlock {
  margin-top: auto;
  padding-top: 8px;
  width: calc(100% + 52px);
  margin-left: -26px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  flex: 1 0 auto;
}

.warningBlock {
  border-radius: 10px;
  padding: 9px 16px;
  font-size: 14px;
  font-weight: bold;
  width: calc(100% - 52px);
  max-width: 327px;
  margin-left: 26px;
  z-index: 2;
  position: relative;
  display: flex;
  position: absolute;
  top: 8px;
  left: 0;
}

.blur {
  background: $color-yellow--dark;
  border-radius: 10px;
  backdrop-filter: blur(44px);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  @supports (backdrop-filter: blur(44px)) {
    background: rgba(255, 184, 0, 0.2);
  }
}

.taxiImageBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%
}

.warningText {
  z-index: 2;
}

.img {
  max-width: 327px;
}

.taxiImg {
  max-width: 327px;
}