@import 'src/styles/vars';
@import 'src/styles/mixins';

.button {
    font-weight: 600;
    font-size: 14px;
    width: auto;
    min-width: 200px;
    max-width: 200px;
    height: auto;
    min-height: 40px;
    line-height: 19px;
    border-radius: 10px;
    cursor: pointer;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    @include touch-transition(background-color);
    font-family: $fontFamilyDefault;
    border: none;
    &.link {
        background: none;
        color: $color-blue;
        font-size: 12px;
        &.active {
            &:hover {
                color: darken($color-blue, 10);
            }
        }
    }
    &.dangerLink {
        background: none;
        color: $color-red--halflight;
        &.active {
            &:hover {
                color: darken($color-red, 10);
            }
        }
    }
    &.outline {
        background-color: $color-white;
        color: $color-black-700;
        border: 2px solid $color-black-700;
        &.active {
            &:hover {
                background: darken($color-white, 2);
            }
        }
    }
    &.primary {
        background: $color-blue;
        color: $color-white;
        outline: none;
        border: none;
        &.active {
            &:hover {
                background: darken($color-blue, 10);
            }
        }
    }
    &.danger {
        $bg-color: rgba($color-red, .3);
        background-color: $bg-color;
        color: $color-red;
        &.active {
            &:hover {
                background: darken($bg-color, 25);
            }
        }
    }
    &.gray {
        $bg-color: rgba($color-gray-bar, 1);
        background-color: $bg-color;
        color: $color-black-900;
        &.active {
            &:hover {
                background: darken($bg-color, 5);
            }
        }
    }

    &.disabled {
        background: $color-black-200;
    }

    &.fullWidth {
        max-width: 100%;
    }
}

.smallButton {
    height: 33px;
}

.loading {
    color: $color-white;
    margin-right: 10px;
}