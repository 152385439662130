@import 'styles/vars';
@import 'styles/mixins';

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + .row {
    margin-top: 8px;
  }
}

.product,
.tariff {
  font-weight: 600;
  margin-right: 16px;
}

.product {
  font-size: 20px;
}

.date {
  color: $color-gray-light;
  font-weight: 500;
  font-size: 14px;
}

.map {
  height: 240px;
  width: 100%;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
  flex: 0 0 240px;
}

.car {
  margin-top: 24px;
}

.carName {
  margin-top: 8px;
}

.rating {
  margin-top: 24px;
}

.ratingScore {
  margin-top: 8px;
  width: 96px;
}

.ratingComment {
  margin-top: 8px;
}

.price {
  margin-top: 24px;
}
