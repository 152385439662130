@import 'styles/vars';

.root {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.segment {}

.label {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: $color-gray-bluish;
  margin-bottom: 12px;
}

.options {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.option {
  padding: 4.5px 10px;
  background-color: $color-gray-bar;
  color: $color-gray-bluish;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  font-weight: 500;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s;

  &.activeOption {
    background-color: $color-blue--light;
    color: $color-blue;
  }
}

.timeWrapper {
  display: flex;
  justify-content: space-between;
}

.time {
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: $color-gray-bluish;
}