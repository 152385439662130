@import 'src/styles/vars';
@import 'src/styles/mixins';

.backStub {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;

  @include touch-transition(opacity);

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.card {
  //position: absolute;
  //bottom: 0;
  //left: 0;
  //width: 100%;
  //height: auto;
  max-height: 80%;
  border-radius: 16px 16px 0 0;
  background-color: $color-white;
  padding: 24px;
  box-shadow: $box-shadow-layer--top;

  @include touch-transition(opacity transform);

  &.hidden {
    transform: translateY(100%);
    opacity: 0;
    pointer-events: none;
  }
}