@import 'styles/vars';
@import 'styles/mixins';

.searchingStub {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 100%;
}

.numberInput {
  margin-top: 20px;
}

.sendOneMoreTime {
  color: $color-blue;
  font-size: 12px;
  margin-top: 20px;
  cursor: pointer;

  &:hover {
    color: darken($color-blue, 10);
  }
}

.resendContainer {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.resendText {
  color: $color-gray-300;
  font-size: 12px;
}

.resendTime {
  color: $color-blue;
  margin-left: 4px;
  font-size: 12px;
}

.btnBlock {
  margin-top: auto;
  widows: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn {
  margin-top: 8px;
  width: auto;
}

.taxiText {
  color: $color-yellow;
}

.checkText {
  width: 100%;
  margin-top: 35px;
  margin-bottom: 9px;
  text-align: center;
  font-size: 12px;
  color: $color-red;
}

.content {
  z-index: 15;
  background: $color-yellow;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow-x: auto;
}

.brandInput,
.modelInput {
  margin-top: 16px;
}

.signIn {
  margin-top: -18px;
}

.q {
  margin-top: 20px;
  margin-bottom: 8px;
}

.a {
  margin-left: 20px;
  font-size: smaller;
}

