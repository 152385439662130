@import 'styles/vars';

m-div {
  display: block;
  font-family: $fontFamilyDefault;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-align: start;
  -text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;

  a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;

    &:hover {
      text-decoration: none;
    }

    &:focus {
      text-decoration: none;
    }

    &:active {
      text-decoration: none;
    }
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
}

:global {
  #widgetRoot {
    position: relative;
    overflow: hidden;
  }
}