@import 'styles/vars';
@import 'styles/mixins';

.root {
  cursor: pointer;
  width: 48px;
  border-radius: 24px;
  padding: 2px;
  display: flex;
  background-color: $color-gray-300;

  @include touch-transition(background-color);

  &.active {
    background-color: $color-blue;
  }

  &.disabled {
    cursor: initial;
    background-color: $color-gray-200;
  }
}

.control {
  height: 20px;
  width: 20px;
  background-color: $color-white;
  border-radius: 50%;

  @include touch-transition(transform background-color);

  &.active {
    transform: translateX(24px);
  }

  &.disabled {
    background-color: $color-gray-100;
  }
}
