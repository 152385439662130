@import 'src/styles/vars';

.root {
  display: flex;
  align-items: center;
  overflow-x: auto;
  width: 100%;
  padding: 8px 0 16px;
  flex-shrink: 0;

  &::-webkit-scrollbar {
    height: 0 !important;
  }
}

.subscription {
  width: 135px;
  height: 60px;
  flex-shrink: 0;
  padding: 10px;
  box-shadow: 4px 4px 13px 0 #00000026;
  margin-right: 16px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  font-weight: bold;
  background: $color-white;
  cursor: pointer;

  &:first-child {
    margin-left: 26px;
  }

  &:last-child {
    margin-right: 26px;
  }

  &.active {
    color: white;
    background-color: $color-blue;
  }
}

.titleBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.activeTag {
  font-size: 10px;
  font-weight: 700;
  color: $color-black-900 !important;
  padding: 1px 3px;
  background: white;
  border-radius: 3px;
}

.period {
  font-size: 12px;
}