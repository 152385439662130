@import 'src/styles/vars';
@import 'src/styles/mixins';

.container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}

.overlay {
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;

  @include touch-transition(opacity);
}

.overlayVisible {
  opacity: 1;
  pointer-events: auto;
}

.menu {
  width: 70%;
  height: 100%;
  padding: 42px 12px 42px 24px;
  background: #fff;
  transform: translateX(-100%);

  @include touch-transition(transform);
}

.menuVisible {
  transform: translateX(0);
}

.icon {
  margin-bottom: 32px;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 24px;
  cursor: pointer;

  &.small {
    font-size: 14px;
    color: $color-blue;
  }
}

.content {
  background: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
