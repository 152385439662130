.notification {
  font-family: 'Manrope', sans-serif;
  font-weight: 600;
  font-size: 13px;
  position: fixed;
  bottom: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  border-radius: 6px;
  padding: 8px;
  background-color: rgba(0,0,0,0.5);
  z-index: 9999;
  color: #FFF;

  animation: fade-in 0.15s, fade-out 0.15s 0.85s ;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}