@import "src/styles/vars";

.rootWrapper {
  position: relative;
  width: 100%;
  height: auto;
  max-height: 90%;
}

.mobileRoot {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 69.6px;
  min-height: 69.6px;
  overflow: hidden;
}

.carwashNotSelected {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70%;
  color: $color-main-black;
  font-weight: 500;
}

.extBtns {
  position: relative;
  bottom: 76.6px;
}

