@import 'src/styles/vars';
@import 'src/styles/mixins';

.productsWrapper {
  padding-bottom: 16px;
}

.button {
  & + .button {
    margin-top: 8px;
  }
}

.service {
  width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 12px 16px;
  background: $color-white;
  
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
}

.titleMain {
  font-size: 14px;
  color: $color-black-900;
  font-weight: 700;

  @include ellipsis;
}

.limit {
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 700;
  color: $color-blue;
  text-align: end;
}

.summary {
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
  color: #737481;
  flex-shrink: 0;
}

.footer {
  padding-top: 8px;
}
