@import 'styles/vars';
@import 'styles/mixins';

.root {
  padding: 10px 14px;
  box-shadow: 4px 4px 15px 0px #0000001A;
  border-radius: 10px;
  background: $color-white;
  cursor: pointer;
  
  & + .root {
    margin-top: 16px;
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: -4px;

  & + .row {
    margin-top: 4px;
  }

  &.indented {
    margin-top: 12px;
  }
}

.title {
  display: flex;
  align-items: center;
  margin-right: 16px;
  flex-wrap: wrap;
}

.product {
  font-size: 16px;
  font-weight: 700;
  margin-right: 6px;
  margin-top: 4px;
}

.status {
  margin-top: 4px;
}

.price {
  font-weight: 700;
  margin-top: 4px;
}

.tariff {
  font-size: 12px;
  font-weight: 600;
  color: $color-gray-light;
}

.date {
  font-size: 12px;
  font-weight: 600;
  color: $color-gray-light;
}

.carName {
  margin-top: 4px;
}

.rating {
  margin-top: 4px;
  flex: 0 0 96px;
}
