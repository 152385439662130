@import 'styles/vars';
.main {
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

.groupTitle {
    margin: 16px 0;
    font-size: 14px;
    font-weight: 600;
    color: $color-gray-170;
}