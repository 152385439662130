@import 'styles/vars';

.root {
  display: inline-flex;
  align-items: center;
  font-weight: 500;

  // Statuses.
  &.created {
    background: $color-blue;
    color: $color-white;
  }
  
  &.progress {
    background: $color-yellow;
  }
  
  &.done {
    background: $color-green--light;
  }

  // Sizes.
  &.sm {
    height: 16px;
    padding: 0 6px;
    font-size: 10px;
    border-radius: 4px;
  }

  &.md {
    font-size: 14px;
    height: 24px;
    padding: 0 12px;
    border-radius: 6px;
  }
}
