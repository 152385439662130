@import 'styles/vars';
@import 'styles/mixins';

.root {
  display: flex;
  flex-direction: column;
}

.container {
  margin-top: 8px;
  background-color: $color-gray-100;
  border-radius: 10px;
  height: 48px;
  display: flex;
  align-items: stretch;
  overflow: hidden;
}

.input {
  display: flex;
  align-items: center;
  flex: 1 1 100%;
  font-size: 16px;
  padding: 0 16px;
  border-radius: inherit;
  outline: none;
  font-family: inherit;
  background: transparent;
  color: $color-black-900;

  border: 1px solid transparent;

  @include touch-transition(border-color color);

  &.error {
    border-color: $color-red;
  }

  &.success {
    border-color: $color-green;
  }

  &:focus {
    border-color: $color-blue;
  }
}

.message {
  margin-top: 4px;
  font-size: 12px;
  line-height: 15px;
  color: $color-black-900;

  @include touch-transition(color);

  &.error {
    color: $color-red;
  }

  &.success {
    color: $color-green;
  }
}
