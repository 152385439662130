@import 'styles/vars';
@import 'styles/mixins';

.root {
  width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  padding: 12px 16px;
  cursor: pointer;
  background: $color-white;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @include touch-transition(box-shadow);

  &:hover {
    box-shadow: 2px 2px 5px 1px rgba(0,0,0, .1);
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
}

.titleMain {
  font-size: 14px;
  color: $color-black-900;
  font-weight: 700;
  
  @include ellipsis;
}

.price {
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 700;
  color: $color-blue;
  text-align: end;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  align-items: flex-end;
}

.description {
  font-size: 12px;
  line-height: 16px;
  color: $color-gray-400;
  flex: 1 1 100%;
  font-weight: 500;
}

.count {
  flex: 0 0 auto;
  margin-left: 8px;
  font-size: 10px;
  line-height: 14px;
  color: #4d94ff;
  font-weight: 600;
}
