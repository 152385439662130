@import 'src/styles/vars';
@import 'src/styles/mixins';

.root {
  padding: $screen-indent--top $screen-indent $screen-indent $screen-indent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $screen-indent;

  &.smallPadding {
    padding: 2px;
  }
}

.control {
  display: flex;
  align-items: center;
  flex: 0 0 24px;
  min-height: 24px;
  cursor: pointer;
  flex-basis: 40px;

  @include touch-transition(color);

  &:hover {
    color: $color-blue;
  }

  &.controlIcon {
    align-items: center;
    justify-content: center;
  }

  //&.left {
  //  justify-content: flex-start;
  //}
  //
  //&.right {
  //  justify-content: flex-end;
  //}
}

.icon {
  height: 16px;
  width: 18px;
}

.content {
  flex: 1 0 auto;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;

  &.left {
    order: -1;
    text-align: left;
  }
}

.bonuses {
  display: flex;
  width: 40px;
  height: 40px;
  background: #FFF;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  color: $color-blue;
  cursor: default;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.divider {
  display: block;
  width: 100%;
  height: 3px;
  background-color: darken($color-gray-disabled, 6);
}