@import 'styles/vars';

.root {
  display: flex;
  flex-direction: column;
  flex: 1 0 240px;
  min-height: 0;
}

.list {
  margin-top: 8px;
  overflow: auto;

  // Respects to scroll bar width.
  padding-right: 12px;
}

.buttonBlock {
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addCarBtn {
  width: auto;
}

.addCarIcon {
  margin-right: 8px;
}
