@import 'styles/vars';
@import 'styles/mixins';

.root {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 12px;
  height: 40px;
  font-size: 14px;
}
