@import 'src/styles/vars';

.table {
  //min-height: 224px;
  background: #FFFFFF;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
}

.tableLayout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 20px;

  & > m-div:nth-child(1) {
    text-align: center;
    width: 30px;
  }
  & > m-div:nth-child(2) {
    text-align: center;
    min-width: 108px;
  }
  & > m-div:nth-child(3) {
    text-align: center;
    min-width: 100px;
  }
}

.tableHead {
  border-radius: 8px;
  background-color: $color-gray-bluish;
  color: #FFF;
}

.tableBody {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: auto;

  & > :first-child {
    margin-top: 12px;
  }
}

.tableItem {
  color: $color-black-900;
  padding: 0 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin: 0.5px 0;

  & .plate {
    border: 1px solid $color-black-900;
    border-radius: 8px;
    padding: 5.5px 0;
    text-align: center;
  }

  &.last {
    background-color: $color-blue--light;
    border-radius: 8px;
    padding: 12px 20px;

    & > m-div:nth-child(2) {
      width: 100%;
    }
  }
  &.my {
    background-color: $color-blue--light;
    border-radius: 8px;
    padding: 5.5px 20px;
    margin: 0;
  }
}

.center {
  margin-top: -12px !important;
  min-height: calc(224px - 49.6px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.noData {
  color: $color-gray-180;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}
