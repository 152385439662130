@import 'styles/vars';
@import 'styles/mixins';

.root {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  
  padding-top: 40px;
  padding-bottom: 40px;
}

.title {
  color: $color-black-900;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
}

.control {
  flex: 0 0 85px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-blue;
  cursor: pointer;

  @include touch-transition(background-color);

  &.error {
    background-color: $color-red;
  }

  &.success {
    background-color: $color-green;
  }
}

.input {
  margin-top: 16px;
}

.inputLabel {
  line-height: 35px;
}

.inputContainer {
  margin-top: 0;
}

.inputElement {
  font-size: 18px;
  width: 200px;

  &.error {
    color: $color-red;
    border-color: transparent;
  }

  &.success {
    color: $color-green;
    border-color: transparent;
  }

  &:focus {
    border-color: transparent;
  }
}
