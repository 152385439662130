.root {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.iframeWrapper {
  width: 100%;
  height: 100%;
  border: none;
  user-select: none;
  touch-action: auto;
}

.iframe {
  width: 100%;
  height: 100%;
}

.iconWrapper {
  display: flex;
  justify-content: end;
  position: absolute;
  padding: 16px;
  right: 16px;
  top: 42px;
  z-index: 16777271;
}

.close {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.over {
  cursor: pointer;
  position: absolute;
  width: 100%;
  bottom: 16%;
  height: 60px;
}