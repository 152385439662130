@import 'styles/vars';

.root {
  color: $color-gray-light;
  position: relative;
  font-size: 16px;
  font-weight: 600;

  &::before {
    position: absolute;
    content: '';
    left: -2px;
    top: 50%;
    right: -2px;
    border-top: 2px solid $color-red;
    transform: rotate(-20deg);
  }
}
