@import 'src/styles/vars';

.rootWrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    max-height: 90%;
}

.root {
    max-height: 90%;
    //overflow: auto;
    overflow: hidden;
}

.closeAnimation {
    animation: close 0.4s ease-out 0ms;
}

@keyframes close {
    100% {
        max-height: 69.6px;
    }
}

.expandBar {
    position: relative;
    top: -14px;
    width: 20%;
    height: 4px;
    border-radius: 3px;
    margin: auto;
    background-color: $color-gray-bar;
}

.extBtns {
    opacity: 1;
    animation: opacityIn 0.38s;
}

@keyframes opacityIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.divider {
    width: calc(100% + 48px);
    margin-bottom: 16px;
    margin-left: -24px !important;
    height: 4px;
    background-color: $color-gray-bar;
}
