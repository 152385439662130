.iframe {
  display: flex;
  flex: 1 0 400px;
  border: 0;
}

.contentCentered {
  align-items: center;
  justify-content: center;
  text-align: center;
}

