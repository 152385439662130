@import 'styles/vars';
@import 'styles/mixins';

.root {
  display: flex;
  justify-content: space-between;
}

.star {
  color: $color-gray-180;

  &.sm {
    height: 16px;
    width: 16px;
  }

  &.md {
    height: 24px;
    width: 24px;
  }

  &.lg {
    height: 48px;
    width: 48px;
  }

  &.interactive {
    cursor: pointer;

    @include touch-transition(color);

    &:hover:not(.selected) {
      color: darken($color-gray-180, 10);
    }
  }

  &.selected {
    color: $color-yellow--dark;
  }
}

.icon {
  height: 100%;
  width: 100%;
}
