@import 'src/styles/vars';
@import 'src/styles/mixins';

.photos {
  height: 280px;
  flex-shrink: 0;
  display: flex;
  overflow-x: auto;
}

.desktopPhotoBlock {
  background: rgba(#c4c4c4, 0.5);
  border-radius: 20px;
  width: auto;
  margin-right: 26px;
  flex-shrink: 0;
  margin-bottom: 20px;
  overflow: hidden;

  &:first-child {
    margin-left: 26px;
  }
}

.desktopPhoto {
  height: 100%;
}

.services {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.serviceIcon {
  flex-shrink: 0;
  margin-right: 12px;
}

.row {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.rowIcon {
  width: 20px;
  flex-shrink: 0;
  margin-right: 10px;
}

.rowText {
  color: $color-gray-700;
  font-size: 14px;
}

.route {
  color: $color-gray-700;
  font-size: 12px;
  margin-top: 20px;
}

.routeTitle {
  font-weight: 700;
}
