@import '../../../styles/vars';
@import '../../../styles/mixins';

.tag {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  border-radius: 10px;
  color: $color-gray-400;
  background: $color-gray-120;
  white-space: nowrap;
  cursor: pointer;

  &.green {
    background: $color-green--light;
    color: $color-green--dark;
  }

  &.yellow {
    color: $color-yellow--dark;
    background: $color-yellow--light;
  }

  &.red {
    background: $color-red--light;
    color: $color-red;
  }

  &.blue {
    background-color: $color-blue--light;
    color: $color-blue;
  }
}

.tagIcon {
  flex-shrink: 0;
  margin-right: 4px;
  color: $color-gray-400;
  display: flex;
  height: 20px;
  font-size: 48px;
}

.tagText {
  font-size: 12px;

  @include ellipsis
}