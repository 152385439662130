@import 'src/styles/vars';
@import 'src/styles/mixins';

// TODO TEST AND MAKE THIS LATER.
// @keyframes fade-to-top {
//   from {
//     opacity: 0;
//     transform: translateY(8px);
//   }

//   to {
//     opacity: 1;
//     transform: none;
//   }
// }

.root {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  @include height-mobile-s {
    overflow: auto;
  }

  // TODO TEST AND MAKE THIS LATER.
  // animation-name: fade-to-top;
  // animation-duration: .2s;
  // animation-timing-function: ease-out;
  // animation-fill-mode: both;

  &.gray {
    background: $color-gray-bg;
  }

  &.white {
    background: $color-white;
  }
}

.header {
  flex: 0 0 auto;
  order: 0;

  @include touch-transition(box-shadow);

  &.shaded {
    box-shadow: $box-shadow-panel--bottom;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  padding: 16px $screen-indent 0 $screen-indent;
  overflow: auto;
  order: 1;

  @include height-mobile-s {
    flex: 1 0 auto;
    overflow: auto;
  }

  &.paddedTop {
    padding-top: $screen-indent--top;
  }

  &.paddedBottom {
    padding-bottom: $screen-indent;
  }

  &.noSidePadd {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.noPadd {
    padding: 0;
  }
}

.footer {
  flex: 0 0 auto;
  padding: $screen-indent;
  order: 2;

  @include touch-transition(box-shadow);

  &.shaded {
    box-shadow: $box-shadow-panel--top;
  }
}
