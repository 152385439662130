.container {
  background: #cecece;
  height: 100%;
  position: relative;
}

.map {
  position: absolute;
  width: 100%;
  height: 100%;
}
