@import 'styles/vars';

.root {
  color: $color-gray-400;
  background: $color-gray-100;
  border: none;
  resize: none;
  font-family: inherit;
  font-weight: 600;
  height: auto;
  outline: none;
  width: 100%;

  &.sm {
    padding: 12px;
    font-size: 12px;
    border-radius: 8px;
  }

  &.md {
    padding: 18px;
    border-radius: 9px;
    font-size: inherit;
  }
}
