@import 'styles/vars';
.menuButton {
    position: absolute;
    left: $screen-indent;
    top: $screen-indent--top;
}

.carsButton {
    position: absolute;
    max-width: calc(100% - 134px);
    left: 50%;
    transform: translateX(-50%);
    top: $screen-indent--top;
}

.authButton {
    min-width: 120px;
    min-height: 40px;
    box-shadow: 4px 4px 20px 0px #00000026;
    font-weight: 600;
    position: absolute;
    top: $screen-indent--top;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
}

.servicesButton {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: $screen-indent;
    font-size: 16px;
    white-space: nowrap;
    &.withSubscription {
        display: flex;
        justify-content: space-between;
        padding: 12px;
        max-width: 360px;
    }
}

.bonuses {
    position: absolute;
    right: $screen-indent;
    top: $screen-indent--top;
    display: flex;
    width: 40px;
    height: 40px;
    background: #FFF;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    color: $color-blue;
    cursor: default;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}