@import 'src/styles/vars';
@import 'src/styles/mixins';

.tag {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  border-radius: 10px;
  color: $color-gray-400;
  background: $color-gray-120;
  white-space: nowrap;
  cursor: pointer;
  width: fit-content;
  gap: 8px;

  &.blue {
    background-color: $color-blue--light;
    color: $color-blue;
  }
}