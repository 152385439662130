@import 'src/styles/vars';

.extraBtns {
  display: flex;
  gap: 15px;

  & > * {
    max-width: calc(50% - 7px);
    min-width: calc(50% - 7px);
  }
}

.link {
  display: block;
  width: 100%;
  text-align: center;
  padding: 12px 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: $color-blue;
}