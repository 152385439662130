@import 'styles/vars';

.root {
  min-height: 28px;
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  padding: 4px 12px;
  margin-left: 10px;

  &.blue {
    color: #fff;
    background: $color-blue;
    border-radius: 10px;
  }

  &.white {
    background: #fff;
    color: $color-blue;
    border-radius: 4px;
  }
}
