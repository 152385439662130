.root {
  position: absolute;
  right: 26px;
  top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
}
