@use 'sass:math';
@import 'styles/vars';
@import 'styles/mixins';

.root {
  $height: 760px;
  $width: 450px;

  position: absolute;
  height: $height;
  max-height: calc(100% - 64px);
  width: $width;
  left: 60px;
  top: calc(50% - #{math.div($height, 2)});
  border-radius: 30px;
  overflow: hidden;

  &.adaptive {
    top: 30px;
  }
}
