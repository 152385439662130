@import 'styles/vars';
@import 'styles/mixins';

.content {
  align-items: center;
  justify-content: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding-bottom: 64px;
}

.title {
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  margin-top: 20px;
}
