@import 'src/styles/vars';

.tag {
  width: fit-content;
  padding: 0 12px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
  cursor: pointer;
  background-color: $color-gray-100;
  color: $color-black-400;
}