@import 'src/styles/vars';

// Text.
@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Transitions.
@mixin transition($properties, $duration, $function) {
  $transition: ();
  @for $i from 1 through length($properties) {
    $transition: append(
      $transition,
      $duration nth($properties, $i) $function,
      $separator: comma
    );
  }

  transition: $transition;
}

@mixin touch-transition($properties) {
  @include transition(($properties), 0.2s, ease-out);
}

@mixin height-mobile-s {
  // iPhone 5 height.
  @media (max-height: 568px) {
    @content;
  }
}
