@import 'src/styles/vars';

.root {
  background: $color-white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.part {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
