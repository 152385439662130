@import 'src/styles/vars';
@import 'src/styles/mixins';

.label {
  display: flex;
  align-items: center;
  user-select: none;
  width: 100%;
  height: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }
}
.cursor {
  cursor: pointer;
}

.input {
  display: none;
}

.checkbox {
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  position: relative;
  top: 0;
  margin-right: 14px;
  width: 14px;
  height: 14px;
  color: #fff;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #000;
}

.checkIcon {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.input:checked~ .checkbox {
  .checkIcon {
    display: flex;
  }
}

.text {
  color: $color-black-700;
  width: 100%;
  font-size: 14px;
}

.checkedIcon {
  width: 8px;
  height: 8px;
  background-color: $color-blue;
  border-radius: 2px;
}