@import 'styles/vars';
@import 'styles/mixins';

.title {
  font-size: 24px;
  font-weight: 600;
  color: $color-black-900;
}

.description {
  margin-top: 16px;
  color: $color-gray-500;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tariff {
  font-weight: 600;
  font-size: 18px;
  color: $color-black-900;
  flex-shrink: 0;
}

.examples {
  font-size: 12px;
  color: $color-gray-500;
  margin-bottom: 12px;
  margin-top: 5px;
}

.tariffsTitle {
  color: $color-black-900;
  font-weight: 600;
  font-size: 24px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.tariffsContainer {
  overflow-y: auto;
}
