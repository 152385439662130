@import 'styles/vars';
@import 'styles/mixins';

.root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: $color-black-900;
  box-sizing: border-box;
  
  * {
    box-sizing: border-box;
  }

  &.fullScreen {
    position: fixed;
  }
}

.overflowHidden {
  overflow: hidden;
}
