@import 'src/styles/vars';
@import 'src/styles/mixins';

.root {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  padding: 16px $screen-indent $screen-indent;
}

.line {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  
  & + .line {
    margin-top: 8px;
  }
}

.title {
  color: $color-gray-400;
}

.duration {
  color: $color-blue;
  font-weight: 600;
}

.name {
  font-size: 24px;
  font-weight: 600;
}

.tag {
  padding: 4px 20px;
  background: $color-blue;
  color: #fff;
  border-radius: 10px;
  font-weight: 600;
}

.priceContainer {
  display: flex;
  margin-left: 16px;
  align-items: flex-end;
}

.price {
  color: $color-blue;
  margin-left: 8px;
  font-size: 24px;
  line-height: 24px;
  font-weight: 600;
}

.description {
  margin: 24px 0 10px;
  color: $color-gray-400;
  font-size: 14px;
  width: 87%;
  line-height: 19px;
}
