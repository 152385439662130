@import 'styles/vars';
.product {
    width: 100%;
    margin-bottom: 32px;
}

.row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.name {
    font-size: 14px;
    font-weight: 700;
}

.price {
    font-size: 14px;
    font-weight: 700;
    color: $color-blue;
}

.description {
    font-size: 12px;
    margin-top: 8px;
    color: $color-gray-400;
    width: 70%;
}