@import 'src/styles/vars';

.imgContainer {
  max-width: 327px;
  max-height: 280px;
  margin: 0 auto;

  & img {
    width: 100%;
    height: 100%;
  }
}

.contentWrapper {
  margin-top: 20px;

  & .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: $color-black-900;
  }

  & .description {
    margin-top: 8px;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: $color-gray-400;
    white-space: pre-line;
  }
}

.footerBtn {
  max-width: 100vw;
}