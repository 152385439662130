.title {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 32px;
}

.row {
  display: flex;
  align-items: center;
  cursor: pointer;

  & + .row {
    margin-top: 16px;
  }
}

.icon {
  margin-left: auto;
}

.itemTitle {
  font-size: 18px;
  font-weight: 600;
}
