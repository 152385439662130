@import 'src/styles/vars';

.action {
  margin-top: 32px;
}

.actionTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: $color-black-900;
  margin-bottom: 8px;
  margin-top: 16px;
}

.actionBody {
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  color: $color-gray-400;
}

.footerBtn {
  max-width: 100vw;
}

.center {
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: $color-gray-180
}