@import "src/styles/vars";

.orderBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #D6E6FF;
  border-radius: 8px;
  margin-top: 4px;
  cursor: pointer;

  & > m-div:nth-child(1) {
    width: 30px;
  }
  & > m-div:nth-child(2) {
    text-align: center;
    min-width: 108px;
  }
  & > m-div:nth-child(3) {
    min-width: 78px;
  }
}

.orderInfo {
  color: $color-black-900;
  padding: 6px 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  & .plate {
    border: 1px solid $color-black-900;
    border-radius: 8px;
    padding: 5.5px 0;
    text-align: center;
    background-color: #fff;
  }

  &.last {
    background-color: $color-blue--light;
    border-radius: 8px;
    padding: 12px 20px;

    & > m-div:nth-child(2) {
      width: 100%;
    }
  }
}