@import 'styles/vars';
@import 'styles/mixins';

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-weight: 600;
}

.title {
  font-size: 20px;
}

.priceContainer {
  flex-shrink: 0;
  display: flex;
  align-items: flex-end;
  margin-left: 16px;
}

.price {
  font-size: 22px;
  line-height: 22px;
  color: $color-blue;
  margin-left: 8px;
}

.subtitle {
  font-size: 18px;
  margin-top: 12px;
  font-weight: 600;
}

.description {
  margin-top: 16px;
  color: $color-gray-500;
  font-size: 12px;
  white-space: pre-wrap;
}

.carTitle {
  color: $color-gray-500;
  margin-top: 20px;
  font-size: 12px;
  padding: 0 26px;
}

.additionalProducts {
  border-radius: 10px;
  color: $color-black-900;
  margin-top: 24px;
}

.button {
  & + .button {
    margin-top: 8px;
  }
}

.item {
  height: 42px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 10px;
  margin-bottom: 5px;
}

.checkedItem {
  background: #d9edff;
}

.checkedItemDiscount {
  background: #d1f9d5;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  height: 100%;
}

.checkboxPriceContainer {
  margin-left: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.checkboxPrice {
  background-color: $color-blue;
  color: $color-white;
  border-radius: 9px;
  padding: 2px 7px;
  font-weight: 600;
}

.checkboxPriceDiscount {
  background-color: $color-green;
}

.oldPrice {
  color: $color-gray-light;
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
  position: relative;
}

.serviceName {
  font-size: 16px;
  color: $color-black-900;
  padding: 0 26px;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}

.servicePrice {
  color: $color-blue;
}

.bonuses {
    margin-top: 40px;
}

.footer {
  padding-top: 8px;
}
