@import 'styles/vars';
@import 'styles/mixins';

.root {
  display: flex;
  width: calc(100% + 48px);
  margin-left: -24px;
  overflow: auto;
  margin-bottom: 8px;
  padding-bottom: 8px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.item {
  padding: 5px 8px;
  border-radius: 10px;
  font-size: 12px;
  color: $color-gray-400;
  white-space: nowrap;
  margin-left: 8px;

  @include transition(all, 0.2s, ease-out);

  &.active {
    color: $color-blue;
    background: $color-blue--light;
  }

  &:last-child {
    margin-right:24px;
  }

  &:first-child {
    margin-left: 24px;
  }
}
