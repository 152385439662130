@import 'styles/vars';

.icon {
  display: block;
  shape-rendering: auto;
  color: $color-blue;
}

.big {
  width: 50px;
  height: 50px;
}

.medium {
  width: 30px;
  height: 30px;
}

.small {
  width: 20px;
  height: 20px;
}
