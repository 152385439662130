@import 'styles/vars';
@import 'styles/mixins';

.root {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;

  padding-top: 40px;
  display: flex;
  flex-direction: column;
}

.cars {
  flex: 1 1 auto;
}
