@import 'styles/vars';
  
.description {
  font-size: 14px;
  line-height: 24px;
  color: black;
  padding-left: 20px;
}

.descriptionItem {
  margin: 0;
  padding: 0;
  display: list-item;
}

.title {
  color: $color-gray-400;
  font-size: 14px;
  line-height: 24px;
  margin-top: 8px;
}