@import 'src/styles/vars';

.header {
  display: flex;
  flex-direction: column;
  align-items: center;

  & .subtitleText {
    font-weight: 600;
    font-size: 12px;
    color: $color-gray-bluish;
  }
}

.referral {
  font-size: 17px !important;
  line-height: 25px;
}

.body {
  display: flex;
  flex-direction: column;
  position: relative;
}

.step {
  display: flex;
  margin-bottom: 30px;
  z-index: 2;
}

.left {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: 8px;

  background: $color-blue;
  color: #FFF;
}

.right {
  display: flex;
  flex-direction: column;
  height: 32px;

  font-weight: 600;
  font-size: 12px;
  line-height: 15px;

  & .directions {
    color: $color-gray-bluish;
  }
}

.dashedLine {
  position: absolute;
  z-index: 1;
  border-left: 2px dashed $color-gray-bluish;
  height: 80%;
  width: 10%;
  top: 5px;
  left: 15.5px;
}