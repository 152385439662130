@import 'src/styles/vars';
@import 'src/styles/mixins';

.root {
  display: flex;
  flex-direction: column;
}

.products {
  display: flex;
  width: calc(100% + 48px);
  margin-left: -24px;
  padding: 0 24px 16px 0;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &.extended {
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    padding-bottom: 0;
  }
}

.nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 12px;
}

.clickArea {
  padding: 4px 0;
  flex: 1 1 100%;

  & + .clickArea {
    margin-left: 11px;
  }

  &.activeItem {
    .navItem {
      background: $color-blue;
    }
  }
}

.navItem {
  height: 4px;
  background: $color-gray-180;
  border-radius: 3px;

  & + .navItem {
      margin-left: 11px;
  }
}

.controls {
  margin-top: 8px;
}

.spinWrapper {
  position: absolute;
  inset: 9px 0 0 0;
}