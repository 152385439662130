@import 'styles/vars';

.title {
  font-size: 12px;
  line-height: 18px;
  color: $color-gray-400;
}

.tariffs {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: $color-gray-100;
  border-radius: 10px;
}

.txTariff {
  border-radius: 10px 0 0 10px;
}

.b2bTariff {
  border-radius: 10px 0 0 10px;
  border-radius: 0 10px 10px 0;
}

.tariff {
  width: 50%;
  height: 48px;
  padding: 9px 20px;
  font-size: 16px;
  background: $color-gray-100;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-gray-500;

  &.selectedTaxi {
    background: $color-yellow--dark;
    color: $color-black-900;
  }

  &.selected {
    background: $color-blue;
    color: white;
  }
}

.tariffDescription {
  color: #AEAEAE;
  margin-top: 4px;
}

.priceInfo {
  padding: 8px;
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: #FFB800;
}