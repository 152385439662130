@import 'styles/vars';
@import 'styles/mixins';

.description {
  color: $color-gray-300;
  margin-top: 16px;
  font-size: 12px;
}

.codeForm {
  margin-top: 40px;
}

.sendOneMoreTime {
  color: $color-blue;
  font-size: 12px;
  margin-top: 16px;
  cursor: pointer;

  &:hover {
    color: darken($color-blue, 10);
  }
}

.resendContainer {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.resendText {
  color: $color-gray-300;
  font-size: 12px;
}

.resendTime {
  color: $color-blue;
  margin-left: 4px;
  font-size: 12px;
}

.errorCode {
  font-size: 12px;
  margin-top: 4px;
  color: $color-red;
}
