.phoneInput {
  margin-bottom: 24px;
}

.content {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
}

.logoutBtnBlock {
  width: 100%;
  display: flex;
  justify-content: center;
}
