@import 'src/styles/vars';
@import 'src/styles/mixins';

.root {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    width: 100%;
}

.name {
    color: $color-black-900;
    @include ellipsis;
    max-width: 110px;
}

.number {
    color: $color-gray-light;
    margin-left: 4px;
}