$fontFamilyDefault: 'Manrope', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  sans-serif;

// Colors.
$color-white: #fff;

$color-blue: #0066ff;
$color-blue--light: #D6E6FF;

$color-red: #ff5151;
$color-red--halflight: #ff6a6a;
$color-red--light: #ffcbcb;

$color-green--dark: #159e23;
$color-green: #30c93f;
$color-green--light: #b8f1bd;

$color-yellow--dark: #ffb800;
$color-yellow: #ffdf8b;
$color-yellow--light: #ffedbe;

$color-main-black: #333745;
$color-black-900: #161616;
$color-black-700: #1f2129;
$color-black-500: #333745;
$color-black-450: #454545;
$color-black-400: #5c5f6a;
$color-black-300: #85878f;
$color-black-200: #adafb5;

$color-gray-bg: #F6F6F6;
$color-gray-50: #fcfcfd;
$color-gray-100: #f2f2f2;
$color-gray-120: #f1f1f2;
$color-gray-150: #ececec;
$color-gray-170: #d8d7d7;
$color-gray-180: #c4c4c4;
$color-gray-200: #989898;
$color-gray-300: #828282;
$color-gray-400: #737481;
$color-gray-500: #676767;
$color-gray-700: #464646;
$color-gray-light: #aaa;
$color-gray-bar: #f7f7f8;
$color-gray-bluish: #9B9CA9;
$color-gray-range: #EBEBEE;
$color-gray-disabled: #F6F6F6;

// Shadows.
$box-shadow-panel: rgba(#000, 0.05);
$box-shadow-panel--top: 0 -3px 9px $box-shadow-panel;
$box-shadow-panel--bottom: 0 3px 9px $box-shadow-panel;

$box-shadow-layer: 20px rgba(#000, 0.15);
$box-shadow-layer--top: 0 -4px 20px rgba(#000, 0.15);
$box-shadow-layer--bottom: 0 4px 20px rgba(#000, 0.15);

// Sizes.
$screen-indent: 26px;
$screen-indent--top: 30px;
