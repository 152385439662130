@import 'src/styles/vars';

.table {
  margin-top: 12px;
}

.infoBlock {
  margin-top: 24px;
  margin-bottom: 18px;
}

.title {
  display: flex;
  justify-content: space-between;
  color: $color-gray-bluish;
  font-size: 13px;

  & > :nth-child(1) {
    font-weight: 600;
    line-height: 16px;
  }

  & > :nth-child(2) {
    font-weight: 500;
    line-height: 18px;
  }
}

.service {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;

  & > :nth-child(1) {
    color: $color-black-900;
  }

  & > :nth-child(2) {
    color: $color-blue;
  }

  &.small {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
  }
}

.extraBtns {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 15px;

  & > * {
    color: $color-black-450 !important;
    min-width: calc(50% - 10px);
    max-width: calc(50% - 10px);
    padding: 8px 16px;
    height: auto;
    & > * {
      margin-left: -8px;
    }
  }
}

.icon {
  margin-right: 12px;
}

.divider {
  height: 2px;
  width: 100%;
  margin: 16px 0;
  background-color: $color-gray-bar;
}

.mt16 {
  margin-top: 16px;
}

.mb16 {
  margin-bottom: 16px;
}