@import "src/styles/vars";

.header {
  padding: 0 24px 4px;
}

.expandBar {
  margin: 8px auto 20px;
  width: 20%;
  height: 4px;
  border-radius: 3px;
  background-color: $color-gray-bar;
}

.weWait {
  color: $color-blue;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

.steps {
  margin-top: 8px;
  display: flex;
  gap: 8px;

  & > m-div {
    width: 33%;
    height: 4px;
    border-radius: 3px;
    background: rgba(0, 102, 255, 0.2);

    &.active {
      background-color: $color-blue;
    }
  }
}

.address {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}
