@import 'src/styles/vars';

.root {
  position: absolute;
  overflow: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #FFF;
  max-height: 100%;
}

.small {
  height: 300px;
  transition: 0.45s;
}

.extended {
  height: calc(100% - 82px);
  transition: 0.45s;
}

.full {
  height: 100%;
  transition: 0.25s;
}

// body
.bodyTitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 2px;
}

// status
.status {
  margin: 4px 0 0 0;
  padding: 8px 20px;
  text-align: center;
}

.textStatus {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}

.placemarkStatus {
  margin-right: 12px;
}

// footer
.footer {
  padding: 4px 24px 24px 24px;
}

// desktop header for mobile
.deskHeader {
  padding: 0 0 16px 0;
}