@import 'styles/vars';
@import 'styles/mixins';

.input {
  font-weight: 700;
}

.overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-white;

  @include touch-transition(opacity transform);

  &.hidden {
    opacity: 0;
    transform: translateY(-8px);
    pointer-events: none;
  }
}

.header {
  flex: 0 0 auto;
  padding: 26px;
  box-shadow: $box-shadow-panel--bottom;
}

.suggestions {
  flex: 1 1 1px;
  overflow: auto;
  padding: 16px 0;
}

.suggestion {
  padding: 16px 26px;
  cursor: pointer;
  font-size: 16px;

  @include touch-transition(background-color);

  &:hover {
    background-color: #f2f6fd;
  }
}
