@import 'styles/vars';
@import 'styles/mixins';
.root {
    display: flex;
    padding: 16px 0;
    cursor: pointer;
    align-items: center;
    &+.root {
        border-top: 1px solid $color-gray-150;
    }
}

.content {
    display: flex;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-left: 8px;
    flex: 1 1 100%;
    align-items: center;
}

.model {
    max-width: 180px;
    @include ellipsis;
}

.grz {
    color: $color-gray-light;
    margin-left: 4px;
}

.checkMark {
    flex: 0 0 auto;
    color: $color-blue;
    transform: scale(0.5);
    opacity: 0;
    @include touch-transition(transform opacity);
    &.visible {
        transform: scale(1);
        opacity: 1;
    }
}